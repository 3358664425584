.slickContainer {
  min-height: 349px;
}

@media (min-width: 1024px) {
  .slickContainer {
    min-height: 424px;
  }
}

.slickContainer :global(.slick-track) {
  display: flex;
}
.slickContainer :global(.slick-track .slick-slide) {
  display: flex;
  height: auto;
}

.slickContainer :global(.slick-track .slick-slide) > div {
  width: 100%;
}
