.pawLoader > div {
  animation-name: paw;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

.pawLoader div:nth-child(2) {
  animation-delay: 0.4s;
  transform: rotate(30deg);
}

.pawLoader div:first-child {
  transform: translate(0px, 30px) rotate(30deg);
  animation-delay: 0.2s;
}

.pawLoader div:last-child {
  margin-top: 20px;
  animation-delay: 0s;
  transform: translate(-28px, -5px) rotate(30deg);
}

@keyframes paw {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
